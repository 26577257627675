import { Navigation, Pagination } from "swiper/modules"
import { CarouselProps } from "~/utils/carouselProps"

export const carouselOptions: CarouselProps = {
  slidesPerView: 1,
  breakpoints: {
    1024: {
      slidesPerView: "auto",
      slidesPerGroup: 3
    }
  },
  navigation: true,
  pagination: {
    type: "progressbar"
  },
  modules: [Navigation, Pagination],
  theme: "navigation-dark-bottom"
}
