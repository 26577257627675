<script setup lang="ts">
import type { ComponentArticlesProps } from "./ComponentArticles.props"
const props = defineProps<ComponentArticlesProps>()
</script>

<template>
  <div class="cms-component-articles">
    <NuxtLazyHydrate when-visible>
      <RailsWiki v-if="props" v-bind="props" />
    </NuxtLazyHydrate>
  </div>
</template>
